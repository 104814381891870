import { mapGetters } from 'vuex';

export default {
  name: 'TemplateSearchTabs',
  props: {
    msg: String,
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      images: [
        'assets/img/icon-flight-pack.png',
        'assets/img/icon-tour-pack.png',
        'assets/img/icon-domestic-vacation.png',
        'assets/img/icon-sport-pack.png',
        'assets/img/icon-more.png',
      ],
      isSearchPage: false,
    };
  },
  computed: {
    ...mapGetters({
      destinationList: 'GET_DOMESTIC_DESTINATION',
      hotelList: 'GET_DOMESTIC_HOTELS',
      holidayData: 'GET_HOLIDAY_DATA',
      device: 'GET_DEVICE',
      mediaSize: 'GET_MEDIA_SIZE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isMultipleSearchCase: 'GET_IS_MULTIPLE_SEARCH_CASE',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
      lang: 'GET_LANGUAGE',
      currentPage: 'GET_CURRENT_PAGE',
    }),
  },
  watch: {
    $route: 'changePageName',
  },
  async created() {
    await this.$store.dispatch('FETCH_DESTINATIONS_ATLANTIS_DEALS');

    if (!this.hotelList.data || this.hotelList.data.length === 0) {
      await this.$store.dispatch('FETCH_DOMESTIC_HOTELS');
    }
    if (!this.holidayData) {
      await this.$store.dispatch('FETCH_HOLIDAY_DATA', new Date().getFullYear());
    }

    this.changePageName();
  },
  methods: {
    changePageName() {
      this.isSearchPage = this.$route.name === 'search-result';
    },
    closeSearchMobile() {
      this.$emit('closeSearchMobile');
    },
  },
};
